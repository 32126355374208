/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'folder-check': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M.5 3l.04.87a2 2 0 00-.342 1.311l.637 7A2 2 0 002.826 14H9v-1H2.826a1 1 0 01-.995-.91l-.637-7A1 1 0 012.19 4h11.62a1 1 0 01.996 1.09L14.54 8h1.005l.256-2.819A2 2 0 0013.81 3H9.828a2 2 0 01-1.414-.586l-.828-.828A2 2 0 006.172 1H2.5a2 2 0 00-2 2m5.672-1a1 1 0 01.707.293L7.586 3H2.19q-.362.002-.683.12L1.5 2.98a1 1 0 011-.98z"/><path pid="1" d="M15.854 10.146a.5.5 0 010 .708l-3 3a.5.5 0 01-.707 0l-1.5-1.5a.5.5 0 01.707-.708l1.146 1.147 2.646-2.647a.5.5 0 01.708 0"/>',
    },
});
